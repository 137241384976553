import { SurveyType } from '@kvika/api-types';
import { SessionPayload } from '../types/Types';

const surveyTypeKey = 'surveyType';
const isLegalEntityKey = 'isLegalEntity';
const tokenKey = 'token';
const sessionPayloadKey = 'sessionPayload';
const externalIdKey = 'externalId';

// Survey type
export const setSurveyType = (surveyType: SurveyType) => {
  sessionStorage.setItem(surveyTypeKey, surveyType);
};

export const getSurveyType = (): SurveyType => {
  const surveyType = sessionStorage.getItem(surveyTypeKey) as SurveyType;
  return surveyType || SurveyType.Aml;
};

export const setIsLegalEntity = (isLegalEntity: boolean) => {
  sessionStorage.setItem(isLegalEntityKey, isLegalEntity.toString());
};

export const getIsLegalEntity = (): boolean => {
  return sessionStorage.getItem(isLegalEntityKey) === 'true';
};

// Token
export const getToken = () => sessionStorage.getItem(tokenKey) || '';
export const setToken = (token: string) => sessionStorage.setItem(tokenKey, token);

// Session Payload
export const getSessionPayload = (): SessionPayload =>
  JSON.parse(sessionStorage.getItem(sessionPayloadKey) || '{"ssn":"","name":"","isLivingAbroad":false}');
export const setSessionPayload = (sessionPayload: SessionPayload) =>
  sessionStorage.setItem(sessionPayloadKey, JSON.stringify(sessionPayload));

export const clearSession = () => {
  const surveyType = getSurveyType();
  const isLegalEntity = getIsLegalEntity();
  sessionStorage.clear();
  setSurveyType(surveyType);
  setIsLegalEntity(isLegalEntity);
};

// External ID for tracking
export const getExternalId = (): string => {
  return sessionStorage.getItem(externalIdKey) ?? '';
};
export const setExternalId = (externalId: string): void => {
  sessionStorage.setItem(externalIdKey, externalId);
};
