import { AppProps } from 'next/app';
import * as React from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import Modal, { ModalBody, ModalHeader, ModalSize } from '@kvika/modal';

import Page from '../components/Page';
import { useApiError } from '../hooks/useApiErrorHook';
import store from '../store';
import {
  hideErrorModal,
  selectError,
  selectModalErrorHeaderText,
  selectModalErrorText,
  selectShowModal,
  selectShowValidationErrors,
  selectValidationErrors,
} from '../store/error';
import { selectLang } from '../store/lang';
import { GlobalStyles } from '../styles/GlobalStyles';
import { getTranslation } from '../utils/languages';
import IndexHead from '../components/IndexHead';

interface AppErrorProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err: any;
}

const GlobalModal = () => {
  const dispatch = useDispatch();
  const showModal = useSelector(selectShowModal);
  const modalErrorText = useSelector(selectModalErrorText);
  const modalErrorHeaderText = useSelector(selectModalErrorHeaderText);
  const showValidationErrors = useSelector(selectShowValidationErrors);
  const validationErrors = useSelector(selectValidationErrors);
  const lang = useSelector(selectLang);

  const validationModalHeader_string = getTranslation(lang, 'Vitlaus innsláttur í forminu', 'Invalid form entry', '');

  return (
    <Modal size={ModalSize.SMALL} isVisible={showModal} onClose={() => dispatch(hideErrorModal())}>
      {showValidationErrors && validationErrors ? (
        <>
          <ModalHeader>{validationModalHeader_string}</ModalHeader>
          <ModalBody>
            {validationErrors.map((error) => (
              <p key={`${error.questionId}-${error.personIndex}-${error.questionProperty}`}>{error.errorMessage}</p>
            ))}
          </ModalBody>
        </>
      ) : (
        <>
          <ModalHeader>{modalErrorHeaderText}</ModalHeader>
          <ModalBody>{modalErrorText}</ModalBody>
        </>
      )}
    </Modal>
  );
};

const ErrorHandler = () => {
  const error = useSelector(selectError);
  const lang = useSelector(selectLang);
  useApiError(lang, error);
  return null;
};

const App = ({ Component, pageProps, err }: AppProps & AppErrorProps) => {
  return (
    <Provider store={store}>
      <GlobalStyles />
      <PersistGate loading={null} persistor={persistStore(store)}>
        <Page>
          <IndexHead />
          <GlobalModal />
          <ErrorHandler />
          <Component {...pageProps} err={err} />
        </Page>
      </PersistGate>
    </Provider>
  );
};

export default App;
