import { StringMap } from '@kvika/api-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

interface StringState {
  data: StringMap;
}

const initialState: StringState = {
  data: {},
};

export const stringSlice = createSlice({
  name: 'strings',
  initialState,
  reducers: {
    updateStrings: (state, action: PayloadAction<StringMap>) => {
      state.data = action.payload;
    },
  },
});

export const { updateStrings } = stringSlice.actions;

export const selectStrings = (state: RootState) => {
  return state.strings.data;
};

export default stringSlice.reducer;
