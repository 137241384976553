import { QuestionError } from '@kvika/survey';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorData, ServiceStatusMode } from '../types/Types';
import { RootState } from './index';

interface RequestError {
  status: number;
  statusText: string;
}

interface ErrorHandling {
  showModal?: boolean;
  modalErrorText?: string;
  modalErrorHeaderText?: string;
  errorStatus?: number;
  showValidationErrors?: boolean;
  validationErrors?: Array<QuestionError>;
}

interface ErrorState {
  requestErrors: RequestError[];
  showModal?: boolean;
  modalErrorText?: string;
  modalErrorHeaderText?: string;
  errorStatus?: number;
  showValidationErrors?: boolean;
  validationErrors?: Array<QuestionError>;
  serviceStatus?: ServiceStatusMode;
  error?: ErrorData;
}

const initialState: ErrorState = {
  requestErrors: [],
  showModal: false,
  showValidationErrors: false,
};

export const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    hideErrorModal: (state) => {
      return { ...state, showModal: false };
    },
    displayError: (state, action: PayloadAction<ErrorHandling>) => {
      return {
        ...state,
        showModal: action.payload.showModal,
        modalErrorText: action.payload.modalErrorText,
        modalErrorHeaderText: action.payload.modalErrorHeaderText,
        errorStatus: action.payload.errorStatus,
        showValidationErrors: action.payload.showValidationErrors,
        validationErrors: action.payload.validationErrors,
      };
    },
    updateServiceStatus: (state, action: PayloadAction<ServiceStatusMode>) => {
      return {
        ...state,
        serviceStatus: action.payload,
      };
    },
    updateError: (state, action: PayloadAction<ErrorData>) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    clearServiceStatus: (state) => {
      return {
        ...state,
        serviceStatus: undefined,
      };
    },
    clearErrorState: (state) => {
      return {
        ...state,
        errorStatus: undefined,
        modalErrorHeaderText: undefined,
        modalErrorText: undefined,
        requestErrors: [],
        showModal: false,
        showValidationErrors: false,
        validationErrors: undefined,
      };
    },
  },
});

export const { displayError, hideErrorModal, updateError, clearErrorState, updateServiceStatus, clearServiceStatus } =
  errorsSlice.actions;
export const selectRequestErrors = (state: RootState) => state.errors.requestErrors;
export const selectShowModal = (state: RootState) => Boolean(state.errors.showModal);
export const selectModalErrorText = (state: RootState) => state.errors.modalErrorText;
export const selectModalErrorHeaderText = (state: RootState) => state.errors.modalErrorHeaderText;
export const selectErrorStatus = (state: RootState) => state.errors.errorStatus;
export const selectValidationErrors = (state: RootState) => state.errors.validationErrors;
export const selectShowValidationErrors = (state: RootState) => state.errors.showValidationErrors;
export const selectServiceStatus = (state: RootState) => state.errors.serviceStatus;
export const selectError = (state: RootState): ErrorData | undefined => state.errors.error;

export default errorsSlice.reducer;
