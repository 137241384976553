import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export interface CompanyState {
  companyId: number | undefined;
  companySsn: string | undefined;
  companyName: string | undefined;
}

const initialState: CompanyState = {
  companyId: undefined,
  companySsn: undefined,
  companyName: undefined,
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanyInfo: (state, action: PayloadAction<CompanyState>) => {
      state.companyId = action.payload.companyId;
      state.companyName = action.payload.companyName;
      state.companySsn = action.payload.companySsn;
    },
    clearCompanyState: (state) => {
      state.companyId = initialState.companyId;
      state.companyName = initialState.companyName;
      state.companySsn = initialState.companySsn;
    },
  },
});

export const { setCompanyInfo, clearCompanyState } = companySlice.actions;

export const selectCompanyInfo = (state: RootState) => state.company;

export default companySlice.reducer;
