import { KYCChildrenSchema } from '@kvika/api-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export interface LoginSuccessfulPayload {
  ssn: string;
  name: string;
  isLivingAbroad?: boolean;
}
export interface AuthState {
  loggedIn?: boolean;
  loggingIn?: boolean;
  user: LoginSuccessfulPayload;
  parent?: LoginSuccessfulPayload;
  children?: KYCChildrenSchema[];
  updateChildren?: boolean;
}

const initialState: AuthState = {
  loggedIn: false,
  loggingIn: false,
  user: { ssn: '', name: '', isLivingAbroad: false },
  parent: { ssn: '', name: '', isLivingAbroad: false },
  children: [],
  updateChildren: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccessful: (state, action: PayloadAction<LoginSuccessfulPayload>) => {
      state.loggedIn = true;
      state.loggingIn = false;
      state.parent = {
        ssn: action.payload.ssn,
        name: action.payload.name,
        isLivingAbroad: action.payload.isLivingAbroad,
      };
      state.user = {
        ssn: action.payload.ssn,
        name: action.payload.name,
        isLivingAbroad: action.payload.isLivingAbroad,
      };
    },
    loginFailed: (state) => {
      state.loggedIn = false;
      state.loggingIn = false;
    },
    loginPending: (state) => {
      state.loggedIn = false;
      state.loggingIn = true;
    },
    logout: (state) => {
      state.loggedIn = false;
      state.loggingIn = false;
    },
    clearAuthState: (state) => {
      state.loggedIn = initialState.loggedIn;
      state.loggingIn = initialState.loggingIn;
      state.parent = { ssn: '', name: '', isLivingAbroad: initialState.parent?.isLivingAbroad };
      state.user = {
        ssn: initialState.user.ssn,
        name: initialState.user.name,
        isLivingAbroad: initialState.user.isLivingAbroad,
      };
      state.children = [];
    },
    setUserInfo: (state, action: PayloadAction<LoginSuccessfulPayload>) => {
      state.user = {
        ssn: action.payload.ssn,
        name: action.payload.name,
        isLivingAbroad: action.payload.isLivingAbroad,
      };
    },
    setChildrenInfo: (state, action: PayloadAction<KYCChildrenSchema[]>) => {
      state.children = action.payload;
    },
    setUpdateChildren: (state, action: PayloadAction<boolean>) => {
      state.updateChildren = action.payload;
    },
  },
});

export const {
  loginSuccessful,
  loginFailed,
  loginPending,
  logout,
  clearAuthState,
  setUserInfo,
  setChildrenInfo,
  setUpdateChildren,
} = authSlice.actions;

export const selectLoggedIn = (state: RootState) => state.auth.loggedIn;
export const selectLoggingIn = (state: RootState) => state.auth.loggingIn;
export const selectAuthState = (state: RootState) => state.auth;

export default authSlice.reducer;
