import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { captureException } from '@sentry/nextjs';
import { getTranslation, Lang } from '../utils/languages';
import { logout } from '../store/auth';
import { clearErrorState, displayError } from '../store/error';
import { clearCompanyState } from '../store/company';
import { clearSession, getSurveyType } from '../utils/authenticationStorage';
import { getErrorEvent } from '../utils/utils';
import { ErrorData } from '../types/Types';

export const useApiError = (lang: Lang, error?: ErrorData) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const somethingWentWrong_string = getTranslation(lang, 'Eitthvað fór úrskeiðis', 'Something went wrong', '');

  const defaultErrorHandled = () => {
    dispatch(
      displayError({
        showModal: true,
        showValidationErrors: false,
        modalErrorText: somethingWentWrong_string,
        modalErrorHeaderText: somethingWentWrong_string,
      })
    );
  };
  useEffect(() => {
    if (error) {
      captureException(new Error('Api error ocurred'), getErrorEvent(error));

      const timeExpiredHeader_string = getTranslation(lang, ' Auðkenning rann út ', 'Session expired ', '');
      const timeExpired_string = getTranslation(
        lang,
        'Vinsamlegast skráðu þig inn aftur.',
        'To continue, please log in again.',
        ''
      );
      if (error.status === 401) {
        const surveyType = getSurveyType();
        // TODO: Move this logic to _app.tsx so that this hook is only used for error handling
        dispatch(clearErrorState());
        dispatch(clearCompanyState());
        dispatch(logout());
        clearSession();
        dispatch(
          displayError({
            showModal: true,
            showValidationErrors: false,
            modalErrorText: timeExpired_string,
            modalErrorHeaderText: timeExpiredHeader_string,
          })
        );
        if (surveyType === 'suitability') {
          router.push('/haefismat');
        } else if (surveyType === 'appropriateness') {
          router.push('/tilhlydileikamat');
        } else {
          router.push('/');
        }
      } else {
        defaultErrorHandled();
      }
    }

    // eslint-disable-next-line
  }, [error, dispatch]);
};
