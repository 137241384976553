import { ReactNode } from 'react';

export enum Lang {
  IS = 'is_IS',
  EN = 'en_US',
  PL = 'pl_PL',
}

type getTranslationType = (lang: Lang, is: string, en: string, pl: string) => string;
export const getTranslation: getTranslationType = (lang, is, en, pl) => {
  switch (lang) {
    case Lang.IS:
      return is;
    case Lang.EN:
      return en;
    case Lang.PL:
      return pl;
    default:
      return is;
  }
};

export const getTranslationNode = (lang: Lang, is: ReactNode, en: ReactNode, pl: ReactNode): ReactNode => {
  switch (lang) {
    case Lang.IS:
      return is;
    case Lang.EN:
      return en;
    case Lang.PL:
      return pl;
    default:
      return is;
  }
};

export const getLanguageStringFromEnum = (lang: Lang) => {
  switch (lang) {
    case Lang.EN:
      return 'English';

    case Lang.PL:
      return 'Polskie';

    default:
      return 'Íslenska';
  }
};
