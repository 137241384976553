import * as React from 'react';

type Props = {
  url: string;
  type: string;
};

const HeadPreloadLink = ({ url, type }: Props) => {
  return <link rel="preload" href={url} as={type} crossOrigin="" />;
};

export default HeadPreloadLink;
