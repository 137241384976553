import { ApiError } from '@kvika/api-client';
import { setTag, setUser } from '@sentry/nextjs';
import { ApiError as ApiErrorText, ErrorData } from '../types/Types';
import { getExternalId } from './authenticationStorage';

/**
 * Gets the boolean value of the isCompany query param which is a string.
 * @param isCompany
 * @returns true if isCompany query param is the string 'true', otherwise false
 */
export const getIsCompany = (isCompany: string | string[] | undefined): boolean => {
  return isCompany === 'true';
};

/**
 * Gets the boolean value of the hasCompanies query param which is a string.
 * The query params denotes whether the current user has access to > 1 companies.
 * @param hasCompanies
 * @returns true if hssCompanies query param is the string 'true', otherwise false
 */
export const getHasMultipleCompanies = (hasCompanies: string | string[] | undefined) => {
  return hasCompanies === 'true';
};

export const getErrorEvent = (apiError: ErrorData, extra?: Record<string, unknown>) => {
  if (apiError) {
    const { status, data, headers } = apiError;
    if (data && headers) {
      const { requestId } = headers;
      setTag('requestId', requestId);
      setUser({ id: getExternalId() });
      const { type, code, detail } = data;
      const detailString = typeof detail === 'string' ? detail : JSON.stringify(detail);
      return {
        message: `${detailString}, errorCode: ${status}}`,
        extra: { code, type, requestId, ...extra },
      };
    }
  }
  // Reset tag if we have a unexpected error so that it doesn't get the requestId tag from the previous error
  setTag('requestId', undefined);
  setUser(null);
  return { message: `An unexpected error occurred}`, extra };
};

export const parseApiError = (error: ApiError): ErrorData => {
  // This is needed due to redux complaining when we pass the raw error which contains functions and other
  // non-serializable objects, right now we only need the request id header so we only include that
  // https://redux.js.org/faq/actions#why-should-type-be-a-string-or-at-least-serializable-why-should-my-action-types-be-constants

  const headers = {
    requestId: error.response?.headers['x-request-id'],
  };
  return {
    data: error.response?.data,
    status: error.response?.status,
    headers,
  };
};
/**
 * Gets the boolean value of the isChild query param which is a string.
 * The query params denotes whether the current user anu
 * @param isChild
 * @returns  true if isChild query param is the string 'true', otherwise false
 */
export const getIsChild = (isChild: string | string[] | undefined) => {
  return isChild === 'true';
};

/**
 * Gets the boolean value of the hasChildren query param which is a string.
 * The query params denotes whether the current user has > 1 child.
 * @param hasChildren
 * @returns true if hssCompanies query param is the string 'true', otherwise false
 */
export const getHasMultipleChildren = (hasChildren: string | string[] | undefined) => {
  return hasChildren === 'true';
};

export const isNotFoundError = (error: ApiError) => {
  const { response } = error;
  if (response && response.status === 404) {
    const { data } = response;
    return data && data.detail && data.detail === ApiErrorText.ANSWER_NOT_FOUND;
  }
  return false;
};
