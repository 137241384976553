import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { Lang } from '../utils/languages';

interface LangState {
  lang: Lang;
}

const initialState: LangState = {
  lang: Lang.IS,
};

export const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    updateLang: (state, action: PayloadAction<Lang>) => {
      state.lang = action.payload;
    },
  },
});

export const { updateLang } = langSlice.actions;

export const selectLang = (state: RootState) => state.language.lang;

export default langSlice.reducer;
