import * as React from 'react';
import Head from 'next/head';
import HeadPreloadLink from './HeadPreloadLink';
import { isStaging } from '../utils/config';

const IndexHead = () => {
  return (
    <Head>
      <title>Kvika | KYC</title>
      {isStaging() && <meta name="robots" content="noindex" />}
      <link rel="icon" href="/favicon.ico" />
      <HeadPreloadLink url="/fonts/AdobeCaslonPro/ACaslonPro-Bold.otf" type="font" />
      <HeadPreloadLink url="/fonts/AdobeCaslonPro/ACaslonPro-BoldItalic.otf" type="font" />
      <HeadPreloadLink url="/fonts/AdobeCaslonPro/ACaslonPro-Italic.otf" type="font" />
      <HeadPreloadLink url="/fonts/AdobeCaslonPro/ACaslonPro-Regular.otf" type="font" />
      <HeadPreloadLink url="/fonts/AdobeCaslonPro/ACaslonPro-Semibold.otf" type="font" />
      <HeadPreloadLink url="/fonts/Roboto/Roboto-Bold.ttf" type="font" />
      <HeadPreloadLink url="/fonts/Roboto/Roboto-Italic.ttf" type="font" />
      <HeadPreloadLink url="/fonts/Roboto/Roboto-Regular.ttf" type="font" />
      <HeadPreloadLink url="/fonts/Roboto/Roboto-Thin.ttf" type="font" />
    </Head>
  );
};

export default IndexHead;
