export enum SegmentTrackingId {
  LoginStarted = 'User Login Inititated',
  LoginCompleted = 'User Login Completed',
  LoginFailed = 'User Login Failed',
  Logout = 'User Logged Out',
  LanguageChangedTo = 'Language Changed To XX',
}

export enum SegmentPageId {
  Home = 'Home',
}

export type AnalyticsEvent = {
  event: SegmentTrackingId | SegmentPageId;
  properties?: Record<string, unknown>;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const trackPage = (url: string, properties?: Record<string, unknown>): void => {
  window.analytics.page(url, properties);
};

export const trackEvent = (analyticsEvent: AnalyticsEvent): void => {
  global.analytics.track(analyticsEvent.event, analyticsEvent.properties);
};
