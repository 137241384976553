import { SurveyType } from '@kvika/api-types';

export const TIER = process.env.NEXT_PUBLIC_KYC_ENV || '';
export const getTier = (): 'production' | 'staging' => {
  if (TIER === 'production' || TIER === 'staging') {
    return TIER;
  }
  // Only allow dev or prod, default to dev if something else.
  return 'staging';
};

export const isProduction = (): boolean => getTier() === 'production';
export const isStaging = (): boolean => getTier() === 'staging';

export const sentryDSN = process.env.NEXT_PUBLIC_SENTRY_DSN || '';

export const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || '';

export const isMifidSurvey = (surveyType: SurveyType) => {
  return surveyType === SurveyType.Suitability || surveyType === SurveyType.Appropriateness;
};
