import React, { FunctionComponent } from 'react';
import Router from 'next/router';
import { trackPage } from '../utils/analytics';
import { getSessionPayload } from '../utils/authenticationStorage';

// Track client-side page views with Segment
Router.events.on('routeChangeComplete', (url) => {
  const sessionData = getSessionPayload();
  trackPage(url, { externalId: sessionData.externalId });
});

type PageProps = {
  children: JSX.Element | JSX.Element[];
};

const Page: FunctionComponent<PageProps> = ({ children }) => <div>{children}</div>;

export default Page;
