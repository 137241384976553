import { ErrorResponseData } from '@kvika/api-client';
import { DisruptionTypeEnum, QuestionSchema, SurveyAnsweredSchema } from '@kvika/api-types';
import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';

export enum SelectedTabType {
  INDIVIDUAL_TYPE = 0,
  LEGAL_ENTITY_TYPE = 1,
}

export interface ErrorData {
  data?: ErrorResponseData;
  status?: number;
  headers?: RawAxiosResponseHeaders | AxiosResponseHeaders;
}

export type StringsResponse = {
  data: StringsData;
};

export type StringsData = {
  [key: string]: string;
};

export type QuestionMap = {
  [key: number]: QuestionSchema;
};

export type QuestionsMap = {
  [key: number]: QuestionSchema[];
};

export type SessionPayload = {
  ssn: string;
  name: string;
  isLivingAbroad: boolean;
  externalId: string;
};

export enum ButtonIcon {
  Add,
}

export type CompanyOrIndividualType = {
  name: string;
  ssn: string;
  lastSurvey?: SurveyAnsweredSchema | null;
};

export enum ApiError {
  ANSWER_NOT_FOUND = 'Answer not found',
  NOT_FOUND = 'not found',
}

export enum ErrorType {
  ERROR_SOMETHING_WENT_WRONG = 'ERROR_SOMETHING_WENT_WRONG',
  ERROR_TIME_EXPIRED_HEADER = 'ERROR_TIME_EXPIRED_HEADER',
  ERROR_TIME_EXPIRED_BODY = 'ERROR_TIME_EXPIRED_BODY',
  ERROR_ELECTRONIC_ID_FAILED = 'ERROR_ELECTRONIC_ID_FAILED',
  ERROR_NUMBER_DOES_NOT_HAVE_EID = 'ERROR_NUMBER_DOES_NOT_HAVE_EID',
  ERROR_EMPTY_FIELD = 'ERROR_EMPTY_FIELD',
  ERROR_INVALID_INPUT_IN_FORM = 'ERROR_INVALID_INPUT_IN_FORM',
  ERROR_FILE_SIZE_EXCEEDED = 'ERROR_FILE_SIZE_EXCEEDED',
  ERROR_FORBIDDEN_FILE_EXTENSION = 'ERROR_FORBIDDEN_FILE_EXTENSION',
  ERROR_OCCURRED = 'ERROR_OCCURRED',
  ERROR_USER_CANCELLATION = 'ERROR_USER_CANCELLATION',
  ERROR_USER_ALREADY_SIGNED = 'ERROR_USER_ALREADY_SIGNED',
  ERROR_SOAPRA_MIGRATION = 'ERROR_SOAPRA_MIGRATION',
  ERROR_SYSTEM_UPDATE = 'ERROR_SYSTEM_UPDATE',
  ERROR_SYSTEM_DISRUPTION = 'ERROR_SYSTEM_DISRUPTION',
  ERROR_SORRY_FOR_THE_TROUBLE = 'ERROR_SORRY_FOR_THE_TROUBLE',
}

export enum ErrorCodes {
  PhoneNumberHasNoElectronicId = 13,
}

export type ServiceStatusMode = {
  type?: DisruptionTypeEnum;
  header?: string;
  message?: string;
};

export enum LoginMethodsEnum {
  ELECTRONIC_ID = 0, // Rafræn skilríki
  ESIM = 1, // Auðkennis appið
}

export type LoginStatusProps = {
  loginRequestToken?: string;
  firstTime: number;
  secondTime: number;
  finalPollingTime: Date;
};
