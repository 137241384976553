import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import authReducer from './auth';
import errorsReducer from './error';
import langReducer from './lang';
import companyReducer from './company';
import stringReducer from './strings';

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['answer', 'counter', 'errors', 'strings'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  errors: errorsReducer,
  company: companyReducer,
  language: langReducer,
  strings: stringReducer,
});

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export default store;
export type RootState = ReturnType<typeof rootReducer>;
